import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SEO from 'components/common/SEO';
import Intro from 'components/landing/Intro';
import Projects from 'components/landing/Projects';
import Skills from 'components/landing/Skills';
import Contact from 'components/landing/Contact';
import Footer from 'components/theme/Footer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO mdxType="SEO" />
    <Intro mdxType="Intro" />
    <Projects mdxType="Projects" />
    <Skills mdxType="Skills" />
    <Contact mdxType="Contact" />
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      